<template>
    <!-- SectionContainer.vue -->
    <v-row :style="`min-height: ${minHeight}px`" align="center" class="fill-height" justify="center" no-gutters>
        <v-card :max-width="maxWidthFormatted" :color="color" flat>
            <v-card-title :class="`${titleClass} ${titleColor}--text`">
                {{ title }}
            </v-card-title>
            <v-card-text :class="`${textClass} ${textColor}--text`">
                {{ text }}
            </v-card-text>
            <slot></slot>
        </v-card>
    </v-row>
</template>

<script>
    export default {
        name: 'SectionContainer',
        props: {
            /**
             * The color of the card
             */
            color: {
                type: String,
                default: 'transparent'
            },
            /**
             * The max width of the card
             */
            maxWidth: {
                type: [String, Number],
                default: '800'
            },
            minHeight: {
                type: Number,
                default: 0
            },
            /**
             * The class applied to the <v-card-title> component
             */
            titleClass: {
                type: String,
                default: 'justify-center text-h6 text-sm-h5 text-md-h4 text-uppercase font-weight-medium'
            },
            /**
             * The class applied to the <v-card-text> component
             */
            textClass: {
                type: String,
                default: 'text-body-1 text-sm-h6 text-md-h5 font-weight-regular mt-5 text-center'
            },
            /**
             * The color of the title
             */
            titleColor: {
                type: String,
                default: 'white'
            },
            /**
             * The text color
             */
            textColor: {
                type: String,
                default: 'white'
            },
            /**
             * The title of the section
             */
            title: {
                type: String,
                required: true
            },
            /**
             * The text of the section
             */
            text: {
                type: String,
                required: true
            }
        },
        computed: {
            maxWidthFormatted () {
                if (this.$vuetify.breakpoint.mobile) {
                    return '95vw'
                }

                return this.maxWidth
            }
        }
    }
</script>
