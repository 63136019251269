<template>
    <section-container
        :min-height="700"
        :text="$t('description')"
        :title="$t('title')"
        text-color="primary"
        title-color="primary"
    >
        <v-card
            class="mx-auto mt-6"
            color="primary"
            dark
            max-width="400"
            :href="href"
            target="_blank"
        >
            <v-img
                src="/img/thai.jpg"
                gradient="to bottom left, rgba(255,255,255,.1), rgba(100,100,100,.5)"
            >
                <v-card-title>
                    <v-icon
                        large
                        left
                    >
                        mdi-bank
                    </v-icon>
                    <div class="text-h6 font-weight-bold">
                        {{ title }}
                    </div>
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold text-center">
                    {{ content }}
                    <div class="caption">{{ subtitle }}</div>
                </v-card-text>

                <v-card-text class="text-center font-weight-bold" v-if="isSpanish">
                    <img src="/img/bcqr.jpg" width="200" class="justify-center align-center">
                    <div>
                        * Escanea el codigo QR con tu aplicacion de Bancolombia
                    </div>
                </v-card-text>
            </v-img>
        </v-card>


        <v-card-text v-if="!isSpanish" class="text-caption text-center font-weight-bold">
            *If you prefer to pay via bank transfer please ask for our details
            <a href="https://wa.me/+447982923353" class="accent--text">via WhatsApp</a>
        </v-card-text>
    </section-container>
</template>

<script>
    import SectionContainer from '@/components/SectionContainer'

    export default {
        name: 'GiftSection',
        data: () => ({
            bancolombia: '278-395186-12',
            collectiv: {
                href: 'https://pay.collctiv.com/honeymoon-28188',
                text: 'pay.collctiv.com'
            }
        }),
        components: {
            SectionContainer
        },
        computed: {
            isSpanish () {
                return this.$i18n.locale === 'es'
            },
            title () {
                return this.isSpanish
                    ? 'Bancolombia'
                    : 'Collctiv'
            },
            content () {
                const vm = this
                return vm.isSpanish
                    ? vm.bancolombia
                    : vm.collectiv.text
            },
            subtitle () {
                return this.isSpanish
                    ? 'Ahorros'
                    : 'Online Payment'
            },
            href () {
                const vm = this
                return vm.isSpanish
                    ? undefined
                    : vm.collectiv.href
            }
        }
    }
</script>

<i18n>
{
    "en": {
        "title": "Gifts",
        "description": "Your presence at our wedding is all that we wish for, however, if you want to give a gift, we will be grateful for a cash donation, or bank transfer towards our honeymoon in 2022.  We are hoping to go to Thailand!"
    },
    "es": {
        "title": "Regalos",
        "description": "Su presencia en nuestra boda es todo lo que deseamos, sin embargo, si desea hacer un regalo, estaremos agradecidos por una donación en efectivo o transferencia bancaria para nuestra luna de miel en 2022. ¡Esperamos ir a Tailandia!"
    }
}
</i18n>
