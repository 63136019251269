<template>
    <v-dialog :value="value" @click:outside="handleClose">
        <v-card :color="color" class="pa-0 ma-0">
            <v-toolbar absolute class="img-toolbar" color="transparent" dense flat width="100%">
                <v-spacer></v-spacer>
                <v-btn color="secondary" elevation="0" fab small @click="handleClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pa-0 ma-0">
                <v-img
                    :contain="!isMobile"
                    :max-height="!isMobile ? '90vh' : undefined"
                    :src="imgSrc"
                ></v-img>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" type="text/css">
    .img-toolbar .v-toolbar__content {
        padding: 16px 16px 0 0;
    }

    .v-dialog {
        width: unset;
    }
</style>

<script>
    export default {
        name: 'BaseImgDialog',
        props: {
            color: {
                type: String,
                default: 'white'
            },
            imgSrc: {
                type: String,
                required: true
            },
            value: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isMobile () {
                return this.$vuetify.breakpoint.mobile
            }
        },
        methods: {
            handleClose () {
                /**
                 * @event close
                 */
                this.$emit('close')
            }
        }
    }
</script>
