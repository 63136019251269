<template>
    <section-container
        :min-height="700"
        :text="$t('story')"
        :title="$t('title')"
        text-color="primary"
        title-color="primary"
    ></section-container>
</template>

<script>
    import SectionContainer from '@/components/SectionContainer'

    export default {
        name: 'StorySection',
        components: {
            SectionContainer
        }
    }
</script>

<i18n>
{
    "en": {
        "title": "We Met In Medellin...",
        "story": "The moment we stop trying to find the right person for us, we start becoming the right person for someone.   That is what happened.  In the least expected place, and at the least expected time we met each other.  We found our favourite person. We have since lived endless crazy moments together.  Moments filled with love, happiness, and incredible experiences.  We are so happy that we can finally make our dream of being together forever a reality.  We are getting married, and we would love to share this special day with all of you!"
    },
    "es": {
        "title": "Nos Conocimos En Medellín...",
        "story": "En el momento en que dejamos de intentar encontrar a la persona adecuada, comenzamos a convertirnos en las personas adecuadas. Así sucedió. En el lugar menos esperado y en el momento menos oportuno, nos dijimos hola. Encontramos nuestra persona favorita y hemos vivido infinidad de momentos locos juntos. Todo este tiempo ha sido lleno de amor, risas e increíbles experiencias. Estamos muy felices de que podamos cumplir este sueño de estar juntos para siempre. Así que, ha llegado nuestro momento. Nos casamos y nos encantaría compartir con ustedes este día tan especial."
    }
}
</i18n>
