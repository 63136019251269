<template>
    <!-- ImageContainer.vue -->
    <v-img
        :aspect-ratio="aspectRatio"
        :gradient="gradient"
        :height="height"
        :min-height="minHeight"
        :src="imgSrc"
    >
        <v-container class="fill-height">
            <!--@slot default-->
            <slot></slot>
        </v-container>
    </v-img>
</template>

<script>
    export default {
        name: 'ImageContainer',
        props: {
            /**
             * The aspect ratio of the image
             */
            aspectRatio: {
                type: String,
                default: '16/10'
            },
            /**
             * Gradient to apply over the image
             */
            gradient: {
                type: String,
                default: 'to top right, rgba(102,102,102,.2), rgba(51,51,51,.4)'
            },
            /**
             * The image height
             */
            height: {
                type: [Number, String],
                default: 770
            },
            /**
             * The src for the image
             */
            imgSrc: {
                type: String,
                required: true
            },
            /**
             * The min image height
             */
            minHeight: {
                type: [Number, String],
                default: 770
            }
        }
    }
</script>
