<template>
    <image-container :img-src="zonaeImage" height="100%" gradient="">
        <v-row justify="center" no-gutters :class="{'my-10': $vuetify.breakpoint.mobile}">
            <v-col cols="12" >
                <section-container
                    :text="$t('description')"
                    :title="$t('wedding_link')"
                ></section-container>
            </v-col>
            <v-col cols="12">
                <v-container fluid>
                    <v-row justify="center">
                        <v-col
                            v-for="event in events"
                            :key="event"
                            class="flex-grow-0"
                        >
                            <v-card
                                class="mx-auto"
                                color="rgba(156,185,209,.8)"
                                height="100%"
                                min-width="330"
                                width="100%"
                            >
                                <v-card-title>
                                    <div class="white--text font-weight-bold">
                                        {{ $t(event).title }}
                                    </div>
                                </v-card-title>
                                <v-divider color="white"></v-divider>
                                <v-list color="transparent">
                                    <v-list-item
                                        v-for="eventItem in eventItems"
                                        :key="eventItem"
                                    >
                                        <v-list-item-icon v-if="eventItemIcons[eventItem]">
                                            <v-icon color="white">{{ eventItemIcons[eventItem] }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-wrap font-weight-bold white--text">
                                                <template v-if="eventItem === EVENT_ITEMS.LOCATION">
                                                    <a
                                                        :href="mapLinks[event]"
                                                        class="white--text"
                                                        target="_blank"
                                                    >
                                                        {{ $t(event)[eventItem] }}
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    {{ $t(event)[eventItem] }}
                                                </template>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </image-container>
</template>

<script>
    import zonaeImage from '@/assets/zonae.jpg'
    // Components
    import ImageContainer from '@/components/ImageContainer'
    import SectionContainer from '@/components/SectionContainer'

    const EVENTS = {
        CEREMONY: 'ceremony',
        RECEPTION: 'reception',
        CARRIAGES: 'carriages'
    }

    const EVENT_ITEMS = {
        INFORMATION: 'information',
        DATE: 'date',
        TIME: 'time',
        LOCATION: 'location'
    }

    const MAP_LINKS = {
        zonae: 'https://g.page/ZonaEllanogrande',
        church: 'https://goo.gl/maps/tVNXwCDf1NX7fg3b9'
    }

    export default {
        name: 'WeddingSection',
        data: () => ({
            zonaeImage,
            EVENTS,
            EVENT_ITEMS,
            MAP_LINKS,
            events: Object.values(EVENTS),
            eventItems: Object.values(EVENT_ITEMS),
            eventItemIcons: {
                [EVENT_ITEMS.LOCATION]: 'mdi-google-maps',
                [EVENT_ITEMS.DATE]: 'mdi-calendar',
                [EVENT_ITEMS.TIME]: 'mdi-clock'
            },
            mapLinks: {
                [EVENTS.CEREMONY]: MAP_LINKS.church,
                [EVENTS.RECEPTION]: MAP_LINKS.zonae,
                [EVENTS.CARRIAGES]: MAP_LINKS.zonae
            }
        }),
        components: {
            ImageContainer,
            SectionContainer
        }
    }
</script>

<i18n>
{
    "en": {
        "description": "The wedding will be on the 19th December 2021, in Llano Grande, Antioquia, Colombia.",
        "ceremony": {
            "title": "Ceremony",
            "information": "The ceremony will be held at a beautiful colonial church in Llano Grande",
            "location": "Parroquia Nuestra Señora del Rosario de Chiquinquirá - El Tablazo, Llano Grande, Antioquia, Colombia",
            "date": "Sunday 19th December 2021",
            "time": "2pm - 3pm"
        },
        "reception": {
            "title": "Reception & Party",
            "information": "The reception & party will be held at the breathtaking El Establo - Zona E in Llano Grande",
            "location": "El Establo - Zona E, Llano Grande, Antioquia, Colombia",
            "date": "Sunday 19th December 2021",
            "time": "3:30pm - 11:45pm"
        },
        "carriages": {
            "title": "Carriages",
            "information": "Please ensure you book your taxi in advance.",
            "location": "El Establo - Zona E, Llano Grande, Antioquia, Colombia",
            "date": "Monday 20th December 2021",
            "time": "12am"
        }
    },
    "es": {
        "description": "La boda será el 19 de Diciembre de 2021, en Llano Grande, Antioquia, Colombia.",
        "ceremony": {
            "title": "Ceremonia",
            "information": "La ceremonia será en una hermosa iglesia colonial en Llano Grande",
            "date": "Domingo 19 de Diciembre 2021",
            "location": "Parroquia Nuestra Señora del Rosario de Chiquinquirá - El Tablazo, Llano Grande, Antioquia, Colombia",
            "time": "2pm - 3pm"
        },
        "reception": {
            "title": "Recepción y Fiesta",
            "information": "La recepción y fiesta se realizarán en el asombroso Establo - Zona E en Llano Grande",
            "date": "Domingo 19 de Diciembre 2021",
            "location": "El Establo - Zona E, Llano Grande, Antioquia, Colombia",
            "time": "3:30pm - 11:45pm"
        },
        "carriages": {
            "title": "Transporte",
            "information": "Asegúrese de reservar su taxi o modo de regreso con anticipación al finalizar la reunión.",
            "date": "Lunes 20 de Diciembre 2021",
            "location": "El Establo - Zona E, Llano Grande, Antioquia, Colombia",
            "time": "12am"
        }
    }
}
</i18n>
