<template>
    <image-container :img-src="mainImage" height="100%"
                     gradient="to top right, rgba(255,255,255,.2), rgba(100,100,100,.4)">
        <v-row :class="{'my-10': $vuetify.breakpoint.mobile}">
            <v-col cols="12">
                <h1 class="display-2 text-center font-weight-medium text-uppercase white--text">
                    {{ $t('title') }}
                </h1>
            </v-col>
            <v-col cols="12">
                <h2 class="text-h5 text-center font-weight-medium text-uppercase white--text">
                    {{ $t('date') }} / {{ $t('location') }} / Colombia
                </h2>
            </v-col>
            <v-col cols="12">
                <v-container fluid class="pa-0">
                    <v-row justify="center">
                        <template v-for="(val, key) in countdown">
                            <v-col
                                :key="key"
                                class="flex-grow-0">
                                <time-card
                                    :key="`${key}_test`"
                                    :subtitle="$t(key)"
                                    :title="val"
                                >
                                </time-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12">
                <!-- Save the date button -->
                <v-col class="text-center" cols="12">
                    <v-btn
                        class="font-weight-bold text-h5"
                        color="rgba(156,185,209,.65)"
                        dark
                        x-large
                        @click="showImage = true"
                    >
                        <v-icon class="mr-1" color="info" large>mdi-heart</v-icon>
                        <div class="white--text">
                            {{ $t('saveTheDate') }}
                        </div>
                    </v-btn>
                </v-col>
                <!-- Save the date image-->
                <base-img-dialog
                    :img-src="imgLink"
                    :value="showImage"
                    @close="showImage = false"
                ></base-img-dialog>
            </v-col>
        </v-row>
    </image-container>
</template>


<script>
    import mainImage from '@/assets/main.jpg'
    // Components
    import BaseImgDialog from '@/components/BaseImgDialog'
    import ImageContainer from '@/components/ImageContainer'
    import TimeCard from '@/components/TimeCard'

    const countdown = () => ({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    })

    export default {
        name: 'HomeSection',
        data: () => ({
            mainImage,
            weddingDate: new Date('2021/12/19 00:00:00'),
            countdown: countdown(),
            timeoutId: false,
            showImage: false
        }),
        components: {
            BaseImgDialog,
            ImageContainer,
            TimeCard
        },
        computed: {
            imgLink () {
                return this.$i18n.locale === 'es'
                    ? '/img/guardaeldia.jpg'
                    : '/img/savethedate.jpg'
            }
        },
        methods: {
            countdownTimer () {
                const vm = this
                const difference = +vm.weddingDate - +new Date()

                if (difference <= 0) {
                    vm.countdown = countdown()
                    clearTimeout(vm.timeoutId)
                    return
                }

                vm.countdown = {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                }

                vm.timeoutId = setTimeout(vm.countdownTimer, 1000)
            }
        },
        created () {
            this.countdownTimer()
        },
        destroyed () {
            clearTimeout(this.timeoutId)
        }
    }
</script>

<i18n>
{
    "en": {
        "title": "We're Getting Married",
        "days": "Days",
        "hours": "Hours",
        "minutes": "Minutes",
        "seconds": "Seconds",
        "location": "Medellin",
        "date": "December 19, 2021",
        "saveTheDate": "Save The Date"
    },
    "es": {
        "title": "Nos Casamos",
        "days": "Dias",
        "hours": "Horas",
        "minutes": "Minutos",
        "seconds": "Segundos",
        "location": "Medellín",
        "date": "19 de Diciembre de 2021",
        "saveTheDate": "Guarda El Dia"
    }
}
</i18n>
