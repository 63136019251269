<template>
    <!-- Main.vue -->
    <v-container class="pa-0" fluid>
        <section
            v-for="section in sectionComponents"
            :id="section.id"
            :key="section.id"
            v-intersect="intersectOptions"
        >
            <component :is="section.component"></component>
        </section>
    </v-container>
</template>

<script>
    import {HOME} from '@/router/route-names'
    import sections from '@/store/static/sections'
    // Sections
    import Home from './sections/Home'
    import Wedding from './sections/Wedding'
    import Story from './sections/Story'
    import Gifts from './sections/Gifts'

    export default {
        name: 'Main',
        data: () => ({
            sections
        }),
        components: {
            Home,
            Wedding,
            Story
        },
        computed: {
            intersectOptions () {
                return {
                    handler: this.onIntersect,
                    options: {
                        threshold: 0.95,
                        rootMargin: '0px 0px'
                    }
                }
            },
            sectionComponents () {
                return [
                    {id: sections.HOME, component: Home},
                    {id: sections.STORY, component: Story},
                    {id: sections.WEDDING, component: Wedding},
                    {id: sections.GIFTS, component: Gifts}
                ]
            }
        },
        methods: {
            onIntersect (a, b, isIntersecting) {
                if (isIntersecting) {
                    const hash = `#${a[0].target.id}`

                    if (hash !== this.$route.hash) {
                        this.$router.push({
                            name: HOME,
                            hash,
                            params: {scroll: false}
                        })
                    }
                }
            }
        }
    }
</script>
