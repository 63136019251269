<template>
    <!-- TimeCard.vue -->
    <v-card :color="color" :width="width">
        <v-card-title :class="`${baseClass} ${titleColor}--text justify-center text-h3`">
            {{ title }}
        </v-card-title>
        <v-card-subtitle :class="`${baseClass} ${subtitleColor}--text text-center text-h5`">
            {{ subtitle }}
        </v-card-subtitle>
    </v-card>
</template>

<script>
    export default {
        name: 'TimeCard',
        props: {
            /**
             * The base class applied to the title and subtitle
             */
            baseClass: {
                type: String,
                default: 'font-weight-medium text-uppercase'
            },
            /**
             * The color of the card
             */
            color: {
                type: String,
                default: 'rgba(156,185,209,.65)'
            },
            /**
             * The subtitle of the card
             */
            subtitle: {
                type: String,
                required: true
            },
            /**
             * The subtitle color
             */
            subtitleColor: {
                type: String,
                default: 'white'
            },
            /**
             * The title of the card
             */
            title: {
                type: [Number, String],
                required: true
            },
            /**
             * The title color
             */
            titleColor: {
                type: String,
                default: 'white'
            },
            /**
             * The width of the card
             */
            width: {
                type: [String, Number],
                default: 162
            }
        }
    }
</script>
